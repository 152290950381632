import styled from 'styled-components';

export interface SideBySideProps extends React.HTMLAttributes<HTMLDivElement> {
	spacing?: number;
	reverseStackOrder?: true;
}

export const SideBySide = styled.div<SideBySideProps>(
	({ spacing = 50, reverseStackOrder = false }) => ({
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'flex-start',
		'@media screen and (max-width: 600px)': {
			flexDirection: reverseStackOrder ? 'column-reverse' : 'column',
			'> :not(:last-child)': {
				marginBottom: spacing,
				marginRight: spacing,
			},
		},
		'> :not(:last-child)': {
			marginRight: spacing,
		},
	})
);
