/**
 * Industries page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { thingosColors } from '../../../shared/src/Theme/theme';
import Container, { ColorContainer, Column } from '../components/Container';
import { Page } from '../components/Page';
import { SideBySide } from '../components/SideBySide';
import { Body, HeaderH2, Mark } from '../components/Typography';
import { Wave } from '../components/Wave';
import { IndexLayout } from '../layouts';

const CompanyRow = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'stretch',
	justifyContent: 'space-between',
	marginTop: 16,
});

const MobileContainer = styled.div({
	marginLeft: '50px',
	'@media screen and (max-width: 600px)': { marginLeft: '0px' },
});

const IndustriesPage = () => {
	const { t } = useTranslation('industries');

	return (
		<IndexLayout>
			<Page>
				<ColorContainer>
					<Container>
						<HeaderH2>{t('industries.areas.title')}</HeaderH2>
						<SideBySide reverseStackOrder>
							<Column flex>
								<Body>
									{t('industries.areas.text1')} <Mark>{t('industries.areas.mark1')}</Mark>,{' '}
									<Mark>{t('industries.areas.mark2')}</Mark> {t('industries.areas.text2')}{' '}
									<Mark>{t('industries.areas.mark3')}</Mark>.
								</Body>
							</Column>
							<StaticImage
								src="../content/carousel_home_areas.jpg"
								alt="All areas"
								style={{ flex: 1, marginBottom: '32px' }}
								objectFit="contain"
							/>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH2>{t('industries.factory.title')}</HeaderH2>
						<SideBySide>
							<Column flex style={{ marginRight: '0', marginBottom: '32px' }}>
								<StaticImage
									src="../content/carousel_home_smart_factory.jpg"
									alt="Smart factory"
									style={{ flex: 1, marginBottom: '16px' }}
									objectFit="contain"
								/>
								<CompanyRow>
									<StaticImage
										src="../content/location-arena2036.jpg"
										alt="Arena2036"
										objectFit="contain"
										width={100}
									/>
									<StaticImage
										src="../content/partners-daimler.png"
										alt="Daimler"
										objectFit="contain"
										width={100}
									/>
									<StaticImage
										src="../content/partners-hpe.png"
										alt="HPE"
										objectFit="contain"
										width={100}
									/>
									<StaticImage
										src="../content/partners-bosch.png"
										alt="Bosch"
										objectFit="contain"
										width={100}
									/>
								</CompanyRow>
							</Column>
							<Column flex>
								<MobileContainer>
									<Body>
										<p>
											{t('industries.factory.text1')} <Mark>{t('industries.factory.mark1')}</Mark>
											{t('industries.factory.text2')}
											<Mark>{t('industries.factory.mark2')}</Mark>
											{t('industries.factory.text3')}
											<Mark>{t('industries.factory.mark3')}</Mark> {t('industries.factory.text4')}
										</p>
										<p>
											{t('industries.factory.text5')}{' '}
											<a href="https://www.arena2036.de/de/">ARENA2036</a>
											{t('industries.factory.text6')}
										</p>
									</Body>
								</MobileContainer>
							</Column>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH2>{t('industries.home.title')}</HeaderH2>
						<SideBySide reverseStackOrder>
							<Column flex style={{ marginBottom: '32px' }}>
								<Body>
									{t('industries.home.text1')}
									<br />
									<br />
									{t('industries.home.text2')}
								</Body>
							</Column>
							<Column flex>
								<StaticImage
									src="../content/carousel-home-smart-home.jpg"
									alt="Smart home"
									style={{ flex: 1, marginBottom: '16px' }}
									objectFit="contain"
								/>
								<CompanyRow>
									<StaticImage
										src="../content/hafele-new.png"
										alt="Häfele"
										objectFit="contain"
										width={100}
										style={{ marginBottom: '32px' }}
									/>
								</CompanyRow>
							</Column>
						</SideBySide>
					</Container>
				</ColorContainer>

				<Wave color={thingosColors.background.dark} />
				<ColorContainer
					backgroundColor={thingosColors.background.dark}
					style={{ paddingBottom: '80px' }}
				>
					<Container>
						<HeaderH2>{t('industries.retail.title')}</HeaderH2>
						<SideBySide>
							<Column flex style={{ marginRight: '0' }}>
								<StaticImage
									src="../content/carousel_home_smart_retail.jpg"
									alt="Smart retail"
									style={{ flex: 1, marginBottom: '16px' }}
									objectFit="contain"
								/>
								<CompanyRow style={{ justifyContent: 'flex-start' }}>
									<StaticImage
										src="../content/demo-netzwerkp.png"
										alt="Netzwerk P"
										objectFit="contain"
										width={100}
									/>
									<StaticImage
										src="../content/location-media-markt-saturn.png"
										alt="MediaMarktSaturn Retail Group"
										objectFit="contain"
										width={120}
										style={{ marginLeft: '2rem', marginBottom: '32px' }}
									/>
								</CompanyRow>
							</Column>
							<Column flex>
								<MobileContainer>
									<Body>
										<p>{t('industries.retail.text1')}</p>
										<p>
											{t('industries.retail.text2')}{' '}
											<a href="http://retailtechhub.com/">Retail Tech Hub</a>
											{t('industries.retail.text3')}{' '}
											<a href="http://www.innovative-retail.de/index.php?id=283">ITH</a>
											{t('industries.retail.text4')}
										</p>
									</Body>
								</MobileContainer>
							</Column>
						</SideBySide>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default IndustriesPage;
